import {FilterOption} from "@/types/front-data-types/filter";

export type MiniDocument = {
    id?: string;
    industries: FilterOption[];
    created_at: string;
    updated_at: string;
    proceedings: FilterOption[];
    material?: FilterOption;
};

export type MiniDocumentList = MiniDocument[];

const defaultMiniDocument: MiniDocument = {
    industries: [],
    created_at: '',
    updated_at: '',
    proceedings: [],
};

const defaultMiniDocumentList: MiniDocumentList = [defaultMiniDocument];

export { defaultMiniDocument, defaultMiniDocumentList };
