











































import { Component, Vue } from 'vue-property-decorator';

import ArMainContainer from '@/components/section/ar-main-container.vue';
import ArMainSidebar from '@/components/section/ar-main-sidebar.vue';

import ArSearch from '@/components/form/ar-search.vue';

import MiniDocs from '@/components/block/mini-docs.vue';
import MainFilters from '@/components/block/main-filters.vue';

import graphql from '@/plugins/graphql';
import {
    defaultFullDocumentList,
    FullDocumentList,
} from '@/types/front-data-types/full-docs';
import GetDocumentList from '@/graphql/documents/GetDocumentList.gql';
import { clone } from '@/helpers/objects';
import { DocumentsByFiltersRequest } from '@/types/requests/documents';
import { Action, Getter } from 'vuex-class';
import { UserType } from '@/types/front-data-types/users';
import { Filter } from '@/types/front-data-types/filter';

@Component({
    name: 'Home',
    components: {
        ArSearch,
        MiniDocs,
        MainFilters,
        ArMainContainer,
        ArMainSidebar,
    },
})
export default class Home extends Vue {
    private lastDocList: FullDocumentList = clone(defaultFullDocumentList);

    @Getter('Users/USER')
    private USER: UserType;
    @Getter('Filters/SELECTED_FILTERS')
    private SELECTED_FILTERS: Filter;

    @Action('Search/CLEAR_SEARCH_DATA')
    CLEAR_SEARCH_DATA: () => void;
    @Action('Filters/CLEAR_SELECTED_FILTERS')
    CLEAR_SELECTED_FILTERS: () => void;

    get userViewedDocs(): FullDocumentList {
        return this.USER.viewedDocuments.map((viewedDoc) => {
            return viewedDoc.document;
        })
    }

    public async setAllData(): Promise<void> {
        const relatedDocuments = await graphql<DocumentsByFiltersRequest>(
            GetDocumentList
        );

        if (!relatedDocuments) return;
        this.lastDocList = clone(relatedDocuments.allDocuments);
    }

    created(): void {
        this.CLEAR_SELECTED_FILTERS();
        this.CLEAR_SEARCH_DATA();
        this.setAllData();
    }
}
