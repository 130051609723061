


















import { Component, Prop, Vue } from 'vue-property-decorator';
import graphql from '@/plugins/graphql';
import allProjects from '@/graphql/projects/allProjects.graphql';
import { AllProjectsResp, Project } from '@/types/requests/projects';

@Component({ name: 'NavProjects' })
export default class NavProjects extends Vue {
    @Prop({ default: false }) readonly isOpen!: boolean;

    private projects: Project[] = [];

    public async getProjects(): Promise<void> {
        const projects: AllProjectsResp | undefined =
            await graphql<AllProjectsResp>(allProjects);
        if (projects && projects.allProjects) {
            this.projects = projects.allProjects;
        }
    }

    async mounted(): Promise<void> {
        await this.getProjects();
    }
}
