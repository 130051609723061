import { ActionContext } from 'vuex';
import graphql from '@/plugins/graphql';
import { clone } from '@/helpers/objects';
import { defaultUser, UserType } from '@/types/front-data-types/users';
import {
    GetUserByTokenRequestType,
    SingleUserResp,
} from '@/types/requests/users';
import GetUserById from '@/graphql/users/GetUser.gql';
import { LikeViewCRequestType } from '@/types/requests/documents';
import LikeDocument from '@/graphql/documents/LikeDocument.gql';
import RemoveDocumentLike from '@/graphql/documents/RemoveDocumentLike.gql';
import AddToChosenDocs from '@/graphql/documents/AddToChosenDocs.gql';
import RemoveFromChosenDocs from '@/graphql/documents/RemoveFromChosenDocs.gql';
import WatchedDocument from '@/graphql/documents/WatchedDocument.gql';
import UpdateWatchedDocument from '@/graphql/documents/UpdateWatchedDocument.gql';
import GetUserByToken from '@/graphql/users/getUserByToken.graphql';

export type UsersState = {
    user: UserType;
};

export default {
    namespaced: true,
    state: {
        user: clone(defaultUser),
    },
    getters: {
        USER: function (state: UsersState): UserType {
            return state.user;
        },
        CHECK_IS_DOC_LIKED_BY_USER:
            (state: UsersState) =>
            (payload: string): boolean => {
                return (
                    state.user &&
                    state.user.likedDocuments &&
                    !!state.user.likedDocuments.find((item) => {
                        return item.id === payload;
                    })
                );
            },
        CHECK_IS_DOC_CHOSEN_BY_USER:
            (state: UsersState) =>
            (payload: string): boolean => {
                return (
                    state.user &&
                    state.user.chosenDocuments &&
                    !!state.user.chosenDocuments.find((item) => {
                        return item.id === payload;
                    })
                );
            },
    },
    mutations: {
        SET_USER(state: UsersState, payload: UserType): void {
            state.user = clone(payload);
        },
    },
    actions: {
        async LOAD_USER_BY_ID(
            context: ActionContext<UsersState, unknown>,
            payload: number
        ): Promise<void> {
            const user = await graphql<SingleUserResp>(GetUserById, {
                where: { id: payload },
            });
            if (user && user.User) {
                context.commit('SET_USER', user.User);
            }
        },
        CHECK_IS_DOC_VIEWED_BY_USER(
            context: ActionContext<UsersState, unknown>,
            payload: string
        ): boolean {
            return !!context.state.user.viewedDocuments.find((item) => {
                return item.document.id === payload;
            });
        },
        async CLICK_ON_DOCUMENT_LIKE_BTN(
            context: ActionContext<UsersState, unknown>,
            payload: string
        ): Promise<void> {
            const liked = await context.getters.CHECK_IS_DOC_LIKED_BY_USER(
                payload
            );
            console.log({ liked });
            if (!liked) {
                await graphql<LikeViewCRequestType>(LikeDocument, {
                    id: payload,
                    userId: context.state.user.id,
                });
            } else {
                await graphql<LikeViewCRequestType>(RemoveDocumentLike, {
                    id: payload,
                    userId: context.state.user.id,
                });
            }
            await context.dispatch('LOAD_USER_BY_ID', context.state.user.id);
        },
        async CLICK_ON_DOCUMENT_CHOSE_BTN(
            context: ActionContext<UsersState, unknown>,
            payload: string
        ): Promise<void> {
            const chosen = await context.getters.CHECK_IS_DOC_CHOSEN_BY_USER(
                payload
            );
            console.log({ chosen });
            if (!chosen) {
                await graphql<LikeViewCRequestType>(AddToChosenDocs, {
                    id: payload,
                    userId: context.state.user.id,
                });
            } else {
                await graphql<LikeViewCRequestType>(RemoveFromChosenDocs, {
                    id: payload,
                    userId: context.state.user.id,
                });
            }
            await context.dispatch('LOAD_USER_BY_ID', context.state.user.id);
        },
        async VIEW_DOC_IF_NOT_VIEWED(
            context: ActionContext<UsersState, unknown>,
            payload: string
        ): Promise<void> {
            const viewed = await context.dispatch(
                'CHECK_IS_DOC_VIEWED_BY_USER',
                payload
            );
            if (!viewed) {
                await graphql<LikeViewCRequestType>(WatchedDocument, {
                    data: {
                        document: {connect: {id: payload}},
                        user: {connect: {id: context.state.user.id}}
                    },
                });
            } else {
                const doc = context.state.user.viewedDocuments.find((document) => {
                    return document.document.id === payload
                })
                if (doc) {
                    await graphql<LikeViewCRequestType>(UpdateWatchedDocument, {
                        id: doc.id,
                        data: {
                            user: {connect: {id: context.state.user.id}}
                        }
                    });
                }
            }
            await context.dispatch(
                'LOAD_USER_BY_ID',
                context.state.user.id
            );
        },
        async loadUser({
            commit,
        }: ActionContext<UsersState, unknown>): Promise<void> {
            const token = localStorage.getItem('authToken') + '';
            const userRequest = await graphql<GetUserByTokenRequestType>(
                GetUserByToken,
                {
                    token,
                }
            );
            if (
                userRequest &&
                userRequest.allUsers &&
                userRequest.allUsers.length > 0
            ) {
                console.log({ user: userRequest.allUsers[0] });
                commit('SET_USER', userRequest.allUsers[0]);
            }
        },
    },
};
