import { ActionContext } from 'vuex';

export type SortState = {
    sortValue: string;
};

export default {
    namespaced: true,
    state: {
        sortValue: '',
    },
    getters: {
        SORT_VALUE: function (state: SortState): string {
            return state.sortValue;
        },
    },
    mutations: {
        SET_SORT_VALUE(state: SortState, payload: string): void {
            state.sortValue = payload;
        },
    },
    actions: {
        CLEAR_SORT_DATA(context: ActionContext<SortState, unknown>): void {
            context.commit('SET_SORT_VALUE', '');
        },
    },
};
