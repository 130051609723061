


















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'ArButton' })
export default class ArButton extends Vue {
    @Prop({ default: false }) readonly isReverse: boolean;
    @Prop({ default: 'button' }) readonly type: string;
    @Prop({ default: '' }) readonly to: unknown;
}
