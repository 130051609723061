










import { Component, Prop, Vue } from 'vue-property-decorator';
import ArButton from '@/components/form/ar-button.vue';

@Component({
    name: 'StaticHint',
    components: {
        ArButton,
    },
})
export default class StaticHint extends Vue {
    @Prop({ default: false }) readonly isOpen: boolean;

    /*get projectIsClosed(): boolean {
        if (localStorage) {
            return localStorage.getItem('projectClosed') === 'true';
        } else {
            return false;
        }
    }*/

    public hide(): void {
        this.$emit('hide');
    }
}
