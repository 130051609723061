
















































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { FullDocument } from '@/types/front-data-types/full-docs';

@Component({
    name: 'ModalDownloadDocument',
})
export default class ModalDownloadDocument extends Vue {
    @Getter('DownloadPopup/IS_VISIBLE')
    readonly IS_VISIBLE: boolean;
    @Getter('DownloadPopup/DOCUMENT')
    readonly DOCUMENT: FullDocument | null;

    @Action('DownloadPopup/CLOSE_POPUP')
    public CLOSE_POPUP: () => void;

    get basicUrl(): string {
        return process.env.VUE_APP_STATIC_URL;
    }
}
