



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { SuggestionType } from '@/types/front-data-types/filter';
import { clone } from '@/helpers/objects';

@Component({ name: 'ArSearch' })
export default class ArSearch extends Vue {
    @Prop({ default: true }) readonly isRedirect: boolean;

    private searchValue = '';

    private currentOption: SuggestionType | null = null;

    private listIsOpen = false;
    private searchIsFocused = false;

    @Getter('Search/SEARCH_VALUE')
    private SEARCH_VALUE: string;
    @Getter('Search/IS_SEARCH_TAG')
    private IS_SEARCH_TAG: string;
    @Getter('Search/SEARCH_SUGGESTIONS_LIST')
    private SEARCH_SUGGESTIONS_LIST: SuggestionType[];

    @Mutation('Search/SET_SEARCH_VALUE')
    public SET_SEARCH_VALUE!: (value: string) => void;

    @Action('Search/LOAD_SEARCH_SUGGESTIONS_LIST')
    public LOAD_SEARCH_SUGGESTIONS_LIST!: (
        searchValue: string
    ) => Promise<void>;

    @Action('Search/CLEAR_SUGGESTIONS_SEARCH_DATA')
    public CLEAR_SUGGESTIONS_SEARCH_DATA!: () => void;

    @Watch('searchValue')
    async onLocalSearchValueChanged(): Promise<void> {
        if (this.searchValue.length > 2) {
            await this.LOAD_SEARCH_SUGGESTIONS_LIST(this.searchValue);
            this.openSuggestionList();
        } else if (this.searchValue.length < 3) {
            this.closeSuggestionList();
            this.CLEAR_SUGGESTIONS_SEARCH_DATA();
        }
    }

    public redirectToSearchPage(): void {
        this.$router.push({
            name: 'search',
        });

        this.closeSuggestionList();
    }
    public redirectToDocumentPage(documentId: string): void {
        this.$router.push({
            name: 'documents-id',
            params: {
                id: documentId,
            },
        });

        this.closeSuggestionList();
    }

    public setValue(newSearchValue: string): void {
        if (this.SEARCH_VALUE !== newSearchValue) {
            this.SET_SEARCH_VALUE(newSearchValue);
        }
        if (this.searchValue !== newSearchValue) {
            this.searchValue = newSearchValue;
        }
    }
    public clearValue(): void {
        this.setValue('');
    }

    public clearCurrentOption(): void {
        this.currentOption = null;
    }

    public openSuggestionList(): void {
        this.listIsOpen = true;
    }
    public closeSuggestionList(): void {
        this.listIsOpen = false;
    }

    public chooseSuggestionOption(option: SuggestionType): void {
        this.setValue(option.name);

        if (option.type === 'doc') {
            this.redirectToDocumentPage(option.value);
        } else {
            if (this.isRedirect) {
                this.redirectToSearchPage();
            }
        }

        this.closeSuggestionList();
    }
    public goToPrevOption(): void {
        const lastOption =
            this.SEARCH_SUGGESTIONS_LIST[
                this.SEARCH_SUGGESTIONS_LIST.length - 1
            ];

        if (!this.currentOption) {
            this.currentOption = clone(lastOption);
        } else {
            this.SEARCH_SUGGESTIONS_LIST.find((option, i) => {
                if (option.value === this.currentOption?.value) {
                    const prevOption = this.SEARCH_SUGGESTIONS_LIST[i - 1];

                    this.currentOption = prevOption
                        ? clone(prevOption)
                        : clone(lastOption);

                    return true;
                }
            });
        }
    }
    public goToNextOption(): void {
        const firstOption = this.SEARCH_SUGGESTIONS_LIST[0];

        if (!this.currentOption) {
            this.currentOption = clone(firstOption);
        } else {
            this.SEARCH_SUGGESTIONS_LIST.find((option, i) => {
                if (option.value === this.currentOption?.value) {
                    const nextOption = this.SEARCH_SUGGESTIONS_LIST[i + 1];

                    this.currentOption = nextOption
                        ? clone(nextOption)
                        : clone(firstOption);

                    return true;
                }
            });
        }
    }

    public arrowHandler(e: KeyboardEvent): void {
        if (
            this.listIsOpen &&
            this.searchIsFocused &&
            this.SEARCH_SUGGESTIONS_LIST.length !== 0
        ) {
            switch (e.keyCode) {
                case 27: // escape
                    this.clearCurrentOption();
                    break;
                case 40: // down arrow
                    console.log('down arrow');
                    this.goToNextOption();
                    break;
                case 38: // up arrow
                    console.log('up arrow');
                    this.goToPrevOption();
                    break;
            }
        }
    }

    public searching(currentValue: string): void {
        if (this.currentOption) {
            this.chooseSuggestionOption(this.currentOption);
            this.clearCurrentOption();
        } else {
            this.setValue(currentValue);

            if (this.isRedirect) {
                this.redirectToSearchPage();
            }

            this.openSuggestionList();
        }
    }

    // ----------------------------------------------------

    created(): void {
        if (document) {
            document.documentElement.addEventListener('click', () => {
                if (this.listIsOpen && !this.searchIsFocused) {
                    this.closeSuggestionList();
                }
            });
        }

        const valueFromGetQuery = this.$route.query.search;

        if (valueFromGetQuery) {
            this.setValue(`#${valueFromGetQuery}`);
        } else {
            this.searchValue = this.SEARCH_VALUE;
        }
    }

    mounted(): void {
        setTimeout(() => {
            this.closeSuggestionList();
        }, 500);
    }
}
