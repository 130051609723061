var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter"},[_c('ul',{staticClass:"filter_categories"},_vm._l((_vm.FILTERS),function(category,i){return _c('li',{key:i,staticClass:"filter_categories_item"},[_c('div',{staticClass:"filter_categories_header",on:{"click":function($event){$event.stopPropagation();return _vm.toggleCategory($event)}}},[_c('p',{staticClass:"filter_categories-name"},[_vm._v(_vm._s(category.title))]),_c('svg',{staticClass:"filter_categories-icon open",attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"x1":"4.94434","y1":"2.11349e-08","x2":"4.94434","y2":"10","stroke":"#232323"}}),_c('line',{attrs:{"x1":"10","y1":"4.94446","x2":"-4.06819e-08","y2":"4.94446","stroke":"#232323"}})]),_c('img',{staticClass:"filter_categories-icon close",attrs:{"src":require("@/assets/icons/filter-minus-blue.svg"),"alt":"Open option"}})]),_c('ul',{staticClass:"filter_options"},_vm._l((category.options),function(option,j){return _c('li',{key:j,staticClass:"filter_options_item",class:{
                        active: _vm.FOUNDED_SELECTED_OPTION({
                            categoryId: category.id,
                            optionId: option.id,
                        }),
                    },on:{"click":function($event){return _vm.toggleOption({
                            categoryId: category.id,
                            option: option,
                        })}}},[_c('img',{staticClass:"filter_options-delete",attrs:{"src":require("@/assets/icons/filter-delete-blue.svg"),"alt":"Delete option"}}),_vm._v(" "+_vm._s(option.name)+" ")])}),0)])}),0),(_vm.SELECTED_FILTERS_TAGS.length !== 0)?_c('div',{staticClass:"filter-result"},[_vm._v(" Знайдено "+_vm._s(_vm.resultCount)+" результатів ")]):_vm._e(),(_vm.SELECTED_FILTERS_TAGS.length !== 0)?_c('div',{staticClass:"filter_selected"},[_c('button',{staticClass:"filter_selected-clear",on:{"click":_vm.CLEAR_SELECTED_FILTERS}},[_vm._v(" Скинути всі фільтри ")]),(_vm.SELECTED_FILTERS_TAGS.length !== 0)?_c('ul',{staticClass:"filter_selected_tags"},_vm._l((_vm.SELECTED_FILTERS_TAGS),function(option,i){return _c('li',{key:i,staticClass:"filter_selected-tag",on:{"click":function($event){return _vm.TOGGLE_OPTION({
                        categoryId: option.category_id,
                        option: option,
                    })}}},[_c('img',{staticClass:"filter_selected-tag-delete",attrs:{"src":require("@/assets/icons/filter-delete-red.svg"),"alt":"Delete tag"}}),_vm._v(_vm._s(option.name)+" ")])}),0):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }