







































































































import { Component, Vue } from 'vue-property-decorator';
import NavProjects from '@/components/block/nav-projects.vue';
import StaticHint from '@/components/hint/big-tooltip.vue';
import { Action, Getter } from 'vuex-class';
import Avatar from '@/components/block/avatar.vue';
import { UserType } from '@/types/front-data-types/users';

@Component({
    name: 'ArHeader',
    components: {
        Avatar,
        NavProjects,
        StaticHint,
    },
})
export default class ArHeader extends Vue {
    private menuIsOpen = false;
    private projectsIsOpen = false;
    private projectHintIsOpen = false;
    @Getter('Users/USER')
    private readonly storeUser: UserType;
    @Action('Users/loadUser')
    private loadUser: () => Promise<void>;

    closeProjectsHint(): void {
        this.projectHintIsOpen = false;
        if (localStorage) {
            localStorage.setItem('projectClosed', 'true');
        }
    }

    async created(): Promise<void> {
        const storageProjectClosed = localStorage.getItem('projectClosed');

        if (!storageProjectClosed) {
            this.projectHintIsOpen = true;
        }

        await this.loadUser();
    }

    private signOut() {
        console.log('Singing out');

        localStorage.removeItem('authToken');
        localStorage.removeItem('expireTime');
        localStorage.removeItem('tempKey');

        this.$router.push('/login');
    }

    mounted(): void {
        if (document) {
            document.documentElement.addEventListener('click', () => {
                console.log(this.projectsIsOpen);
                if (this.projectsIsOpen) {
                    this.projectsIsOpen = false;
                }
            });
        }
    }
}
