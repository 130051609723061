
export type FileType = {
    encoding: string
    filename: string
    id: string
    mimetype: string
    originalFilename: string
    path: string
    publicUrl: string
}

export const defaultFile = {
    encoding: '',
    filename: '',
    id: '',
    mimetype: '',
    originalFilename: '',
    path: '',
    publicUrl: '',
}
