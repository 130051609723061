







import { Component, Vue } from 'vue-property-decorator';
import ArHeader from '@/components/section/ar-header.vue';
import ArSearch from '@/components/form/ar-search.vue';
import MiniDocs from '@/components/block/mini-docs.vue';
import MainFilters from '@/components/block/main-filters.vue';
import ArMainContainer from '@/components/section/ar-main-container.vue';

@Component({
    name: 'DefaultLayout',
    components: {
        ArHeader,
        ArSearch,
        MiniDocs,
        MainFilters,
        ArMainContainer,
    },
})
export default class DefaultLayout extends Vue {}
