

















import { Component, Vue } from 'vue-property-decorator';
import ArButton from '@/components/form/ar-button.vue';
import CreateSearchRequest from '@/graphql/documents/CreateSearchRequest.gql'
import graphql from "@/plugins/graphql";
import {CreateSearchDocReqType} from "@/types/requests/documents";
import {Getter} from "vuex-class";
import {UserType} from "@/types/front-data-types/users";

@Component({
    name: 'NotFound',
    components: {
        ArButton,
    },
})
export default class NotFound extends Vue {
    @Getter('Users/USER')
    private user: UserType;

    private text = '';
    private btnText = 'Відправити'

    async sendSearchRequest (): Promise<void> {
        if (this.text.length > 0) {
            const res = await graphql<CreateSearchDocReqType>(CreateSearchRequest, {
                data: {
                    comment: this.text,
                    user: {connect: {id: this.user.id}}
                }
            })
            if (res && res.createSearchDocRequest) {
                this.text = ''
                this.btnText = 'Отправлено'
            }
        }

    }
}
