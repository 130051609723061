import { MiniDocument } from '@/types/front-data-types/mini-docs';
import { defaultUser, UserType } from '@/types/front-data-types/users';
import { FileType } from '@/types/front-data-types/base/file';
import { clone } from '@/helpers/objects';
import { FilterOption } from '@/types/front-data-types/filter';
import { CommentType } from '@/types/requests/documents';

export type FullDocument = MiniDocument & {
    title: string;
    isBook: boolean;
    author: string;
    language: FilterOption | null;
    additional_language: FilterOption | null;
    isTemplate?: boolean;
    description: string;
    full_description: string;
    fullText: string;
    image?: FileType;
    document?: FileType;
    extraDocs?: FileType[];
    document_url: string;
    downloads: number;
    owner: UserType;
    subAuthors: UserType[];
    tags: FilterOption[];
    type?: FilterOption | null;
    project: FilterOption | null;
    year: string;
    likes: { id: string }[];
    views: { id: string }[];
    comments: CommentType[];
    chosenByUsers: { id: string }[];
    static_created_date: string;
    static_updated_date: string;
};

export type ViewedDoc = {
    id?: string,
    user: UserType,
    document: FullDocument,
    created_at: string,
    updated_at: string,
}

const defaultFullDocument: FullDocument = {
    static_updated_date: '',
    title: '',
    isBook: false,
    description: '',
    full_description: '',
    fullText: '',
    industries: [],
    document_url: '',
    additional_language: null,
    tags: [],
    downloads: 0,
    created_at: '',
    updated_at: '',
    proceedings: [],
    subAuthors: [],
    owner: clone(defaultUser),
    author: '',
    language: null,
    comments: [],
    project: null,
    year: '',
    likes: [],
    views: [],
    static_created_date: '',
    chosenByUsers: [],
};

export type FullDocumentList = FullDocument[];
const defaultFullDocumentList: FullDocumentList = [];

export type FullDocumentListFrontData = {
    count: number;
    list: FullDocumentList;
};
const defaultFullDocumentListFrontData: FullDocumentListFrontData = {
    count: 0,
    list: defaultFullDocumentList,
};

export {
    defaultFullDocument,
    defaultFullDocumentList,
    defaultFullDocumentListFrontData,
};
