
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { defaultMiniDocument } from '@/types/front-data-types/mini-docs';
import { getDateMini } from '@/helpers/date';
import { FullDocument } from '@/types/front-data-types/full-docs';

@Component({ name: 'MiniDoc' })
export default class MiniDoc extends Vue {
    @Prop({ default: () => defaultMiniDocument })
    readonly docData: FullDocument;

    private get projectTitle(): string {
        return this.docData.project?.name || '';
    }

    private get createdAt(): string {
        return this.docData.static_created_date
            ? getDateMini(this.docData.static_created_date)
            : getDateMini(this.docData.created_at);
    }
}
