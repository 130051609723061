






import DefaultLayout from '@/layouts/DefaultLayout.vue';
import {Vue} from "vue-property-decorator";


export default class App extends Vue {

    get layout(): Vue {
        return this.$route.meta.layout || DefaultLayout;
    }
}
