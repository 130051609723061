import {
    defaultFilterOption,
    FilterOption,
} from '@/types/front-data-types/filter';
import {
    FullDocument,
    FullDocumentList,
} from '@/types/front-data-types/full-docs';
import { clone } from '@/helpers/objects';

export type DepartmentType = FilterOption & {
    documents: FullDocumentList;
    _documentsMeta: { count: number };
};

export type AllAuthorsRequest = {
    allAuthors: FilterOption[];
};
export type AllDocumentsTypesRequest = {
    allDocumentTypes: FilterOption[];
};
export type AllDocumentsDepartmentsRequest = {
    allInternalDocsDepartments: DepartmentType[];
};
export type AllIndustriesRequest = {
    allIndustries: FilterOption[];
};
export type AllLanguagesRequest = {
    allLanguages: FilterOption[];
};
export type AllMaterialsRequest = {
    allMaterials: FilterOption[];
};
export type AllProceedingsRequest = {
    allProceedings: FilterOption[];
};
export type AllProjectsRequest = {
    allProjects: FilterOption[];
};
export type AllOwnersAndYearsRequest = {
    allDocuments: FullDocument[];
};
export type DocumentsByFilterRequest = {
    allDocuments: FilterOption[];
};
export type AllFilters = [
    AllAuthorsRequest?,
    AllDocumentsTypesRequest?,
    AllIndustriesRequest?,
    AllLanguagesRequest?,
    AllMaterialsRequest?,
    AllProceedingsRequest?,
    AllProjectsRequest?,
    AllOwnersAndYearsRequest?
];

export type QueryObject = {
    where: WhereObject;
    sortBy?: SortEnum[];
    skip?: number;
};

const defaultQueryObject: QueryObject = {
    where: {
        project: undefined,
        title_contains_i: undefined,
        fullText_contains_i: undefined,
        proceedings_some: undefined,
        subAuthors_some: undefined,
        industries_some: undefined,
        material: undefined,
        type: undefined,
        owner: undefined,
        year_in: undefined,
        language: undefined,
        additional_language: undefined,
        tags_some: undefined,
    },
};

export enum SortEnum {
    // noinspection JSUnusedGlobalSymbols
    id_DESC = 'id_DESC',
    title_ASC = 'id_DESC',
    year_ASC = 'id_DESC',
}
export enum Order {
    // noinspection JSUnusedGlobalSymbols
    ASC,
    DESC,
}

export type SortBy = {
    field: string;
    order: Order;
};

export type WhereObject = {
    title_contains_i?: string;
    fullText_contains_i?: string;
    proceedings_some?: WhereIdInObject;
    owner?: WhereIdInObject;
    subAuthors_some?: WhereIdInObject;
    material?: WhereIdInObject;
    industries_some?: WhereIdInObject;
    tags_some?: WhereNameIObject;
    type?: WhereIdInObject;
    project?: WhereIdObject;
    language?: WhereIdInObject;
    additional_language?: WhereIdInObject;
    year_in?: string[];
    isBook?: boolean;
    OR?: Record<any, unknown>[];
    AND?: Record<any, unknown>[];
};

export type WhereIdInObject = {
    id_in: string[];
};
export type WhereIdObject = {
    id: string;
};
export type WhereNameObject = {
    name: string[];
};
export type WhereNameIObject = {
    name_i: string;
};

export class Filters {
    public owners: FilterOption[];
    public authors: FilterOption[];
    public documentTypes: FilterOption[];
    public industries: FilterOption[];
    public languages: FilterOption[];
    public materials: FilterOption[];
    public proceedings: FilterOption[];
    public projects: FilterOption[];
    public years: FilterOption[];

    constructor(allFilters: AllFilters) {
        if (allFilters[0] && allFilters[0].allAuthors) {
            this.authors = allFilters[0].allAuthors;
        }
        if (allFilters[1] && allFilters[1].allDocumentTypes) {
            this.documentTypes = allFilters[1].allDocumentTypes;
        }
        if (allFilters[2] && allFilters[2].allIndustries) {
            this.industries = allFilters[2].allIndustries;
        }
        if (allFilters[3] && allFilters[3].allLanguages) {
            this.languages = allFilters[3].allLanguages;
        }
        if (allFilters[4] && allFilters[4].allMaterials) {
            this.materials = allFilters[4].allMaterials;
        }
        if (allFilters[5] && allFilters[5].allProceedings) {
            this.proceedings = allFilters[5].allProceedings;
        }
        if (allFilters[6] && allFilters[6].allProjects) {
            this.projects = allFilters[6].allProjects;
        }
        if (allFilters[7] && allFilters[7].allDocuments) {
            console.log({ docs: allFilters[7].allDocuments });
            const owners: FilterOption[] = [];
            const yearsOptions: FilterOption[] = [];
            const years: string[] = [];
            let i = 1;

            allFilters[7].allDocuments.forEach((document) => {
                const ownerFilterOption: FilterOption =
                    clone(defaultFilterOption);
                if (document.owner && document.owner.id) {
                    ownerFilterOption.id = document.owner.id;
                    ownerFilterOption.name = document.owner.name;
                    if (
                        !owners.find((item) => {
                            return item.id === ownerFilterOption.id;
                        })
                    ) {
                        owners.push(ownerFilterOption);
                    }
                }
                if (years.indexOf(document.year) === -1) {
                    years.push(document.year);
                }
                if (document.subAuthors) {
                    document.subAuthors.forEach((author) => {
                        const subAuthorFilterOption: FilterOption =
                            clone(defaultFilterOption);

                        if (author && author.id) {
                            subAuthorFilterOption.id = author.id;
                            subAuthorFilterOption.name = author.name;
                            if (
                                !owners.find((item) => {
                                    return item.id === subAuthorFilterOption.id;
                                }) &&
                                !owners.find((item) => {
                                    return item.id === subAuthorFilterOption.id;
                                })
                            ) {
                                owners.push(subAuthorFilterOption);
                            }
                        }
                    });
                }
            });

            years.sort(function (a, b) {
                return parseInt(a) - parseInt(b);
            });
            years.forEach((year) => {
                const yearOptionItem: FilterOption = clone(defaultFilterOption);
                yearOptionItem.id = i.toString();
                yearOptionItem.name = year;
                yearsOptions.push(yearOptionItem);
                i++;
            });
            console.log({ owners });
            this.years = yearsOptions;
            this.authors = owners;
        }
    }
}

export { defaultQueryObject };
