import { ActionContext, GetterTree } from 'vuex';
import GetDocumentTags from '@/graphql/documents/GetDocumentTags.graphql';
import GetDocumentsBySearch from '@/graphql/filters/GetDocsBySearch.gql';
import {DocumentsByFiltersRequest, TagsRequestType} from '@/types/requests/documents';
import graphql from '@/plugins/graphql';
import {SuggestionType} from "@/types/front-data-types/filter";

export type SearchState = {
    searchValue: string;
    searchSuggestionsList: SuggestionType[];
};

// noinspection JSUnusedGlobalSymbols
export default {
    namespaced: true,
    state: {
        searchValue: '',
        searchSuggestionsList: [],
    },
    getters: <GetterTree<SearchState, any>>{
        IS_SEARCH_TAG: function (state: SearchState, getters): boolean {
            return getters.SEARCH_VALUE.indexOf('#') === 0;
        },
        SEARCH_VALUE: function (state: SearchState): string {
            return state.searchValue.trim();
        },
        SEARCH_SUGGESTIONS_LIST: function (state: SearchState): SuggestionType[] {
            return state.searchSuggestionsList;
        },
    },
    mutations: {
        SET_SEARCH_VALUE(state: SearchState, payload: string): void {
            state.searchValue = payload;
        },
        SET_SEARCH_SUGGESTIONS_LIST(
            state: SearchState,
            payload: SuggestionType[]
        ): void {
            state.searchSuggestionsList = [...payload];
        },
    },
    actions: {
        async LOAD_SEARCH_SUGGESTIONS_LIST(
            context: ActionContext<SearchState, unknown>,
            payload: string
        ): Promise<void> {
            try {
                let result:SuggestionType[] = []
                const isTag = payload.substr(0, 1) === '#';
                if (isTag) {
                    const tagResult = await graphql<TagsRequestType>(
                        GetDocumentTags, {data: {name_contains_i: payload.substr(1)}}
                    );
                    if (tagResult) {
                        result = tagResult.allDocumentTags.map((item) => {
                            const suggestion: SuggestionType = {
                                type: 'tag',
                                name: `#${item.name}`,
                                value: item.id ?? ''
                            };
                            return suggestion
                        })
                    }
                } else {
                    const docResult = await graphql<DocumentsByFiltersRequest>(
                        GetDocumentsBySearch,
                        {where: {title_contains_i: payload}}
                    );
                    if (docResult) {
                        result = docResult.allDocuments.map((item) => {
                            const suggestion: SuggestionType = {
                                type: 'doc',
                                name: item.title,
                                value: item.id ?? ''
                            }
                            return suggestion;
                        })
                    }
                }
                context.commit('SET_SEARCH_SUGGESTIONS_LIST', result);
            } catch (e) {
                context.commit('SET_SEARCH_SUGGESTIONS_LIST', []);
                console.error('Error, loading suggestions list', e);
            }
        },
        CLEAR_SEARCH_DATA(context: ActionContext<SearchState, unknown>): void {
            context.commit('SET_SEARCH_VALUE', '');
            context.commit('SET_SEARCH_SUGGESTIONS_LIST', []);
        },
        CLEAR_SUGGESTIONS_SEARCH_DATA(context: ActionContext<SearchState, unknown>): void {
            context.commit('SET_SEARCH_SUGGESTIONS_LIST', []);
        },
    },
};
