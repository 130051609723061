











































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Filter, FilterOption } from '@/types/front-data-types/filter';
import { Action, Getter } from 'vuex-class';

@Component({
    name: 'MainFilters',
})
export default class MainFilters extends Vue {
    @Prop({ default: null }) readonly resultCount: number;
    @Prop({ default: false }) readonly isRedirect: boolean;

    // region Vuex
    @Getter('Filters/FILTERS')
    private FILTERS: Filter;
    @Getter('Filters/SELECTED_FILTERS_TAGS')
    private SELECTED_FILTERS_TAGS: FilterOption[];
    @Getter('Filters/FOUNDED_SELECTED_OPTION')
    private FOUNDED_SELECTED_OPTION: (payload: {
        categoryId: string;
        optionId: string;
    }) => boolean;

    @Action('Filters/LOAD_ALL_FILTERS')
    public LOAD_ALL_FILTERS!: () => Promise<void>;
    @Action('Filters/CLEAR_SELECTED_FILTERS')
    public CLEAR_SELECTED_FILTERS!: () => void;
    @Action('Filters/TOGGLE_OPTION')
    public TOGGLE_OPTION!: (payload: {
        categoryId: string;
        option: FilterOption;
    }) => void;
    // endregion

    // region methods
    public toggleCategory(e: Event): void {
        const target = e.target as HTMLElement;
        const categoryItem = target.closest('.filter_categories_item');
        categoryItem?.classList?.toggle('opened');
    }
    public toggleOption(payload: {
        categoryId: string;
        option: FilterOption;
    }): void {
        this.TOGGLE_OPTION({
            categoryId: payload.categoryId,
            option: payload.option,
        });
        console.log('is redirect', this.isRedirect);
        if (this.isRedirect) {
            this.$router.push({
                name: 'search',
            });
        }
    }
    // endregion

    async created(): Promise<void> {
        await this.LOAD_ALL_FILTERS();
    }
}
