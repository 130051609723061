import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

import DefaultFoundLayout from '@/layouts/DefaultFoundLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import moment from 'moment';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/login',
        name: 'auth-sign_in',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/auth/SignIn.vue'),
        meta: {
            layout: AuthLayout,
        },
    },
    {
        path: '/browsing-history',
        name: 'browsing_history',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/BrowsingHistory.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/internal-documents',
        name: 'internal_documents',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/InternalDocuments.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/search',
        name: 'search',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/Search.vue'),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/documents/:id',
        name: 'documents-id',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/documents/_id.vue'),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/projects/:id',
        name: 'projects-id',
        component: () =>
            import(
                /* webpackChunkName: "singleProject" */ '@/views/projects/singleProject.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/projects/:id/card',
        name: 'projects-card',
        component: () =>
            import(
                /* webpackChunkName: "projectCard" */ '@/views/projects/projectCard.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/books',
        name: 'books',
        component: () =>
            import(
                /* webpackChunkName: "singleProject" */ '@/views/books/allBooks.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },

    // FIGMA SCREEN ROUTING
    {
        path: '/screens/main-lab',
        name: 'screen-main_lab',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/screens/MainLab.vue'
            ),
    },
    {
        path: '/screens/main-browsing-history',
        name: 'screen-main_browsing_history',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/screens/MainBrowsingHistory.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/screens/search-filter',
        name: 'screen-search_filter',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/screens/SearchFilter.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/screens/search-fail',
        name: 'screen-search_fail',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/screens/SearchFail.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/screens/search-results-filter',
        name: 'screen-search_results_filter',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/screens/SearchResultsFilter.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/screens/agreement',
        name: 'screen-agreement',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/screens/Agreement.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/screens/books',
        name: 'screen-books',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/screens/Books.vue'),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/screens/document',
        name: 'screen-document',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/screens/Document.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/screens/book',
        name: 'screen-book',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/screens/Book.vue'),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
    {
        path: '/screens/internal-documents',
        name: 'screen-internal_documents',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/screens/InternalDocuments.vue'
            ),
        meta: {
            layout: DefaultFoundLayout,
            requireAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (from, to, next) => {
    const shouldRun = from.meta.requireAuth;
    // const shouldRun = false;
    if (shouldRun) {
        const token = localStorage.getItem('authToken');
        const expireTime = localStorage.getItem('expireTime');
        if (!token || !expireTime || +expireTime < moment().unix()) {
            // localStorage.clear();
            localStorage.removeItem('authToken');
            localStorage.removeItem('expireTime');
            localStorage.removeItem('tempKey');
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
