import { ActionContext } from 'vuex';
import { clone } from '@/helpers/objects';
import { FullDocument } from '@/types/front-data-types/full-docs';

export type DownloadPopupState = {
    isVisible: boolean;
    document: FullDocument | null;
};

export default {
    namespaced: true,
    state: {
        isVisible: false,
        document: null,
    },
    getters: {
        IS_VISIBLE: function (state: DownloadPopupState): boolean {
            return state.isVisible;
        },
        DOCUMENT: function (state: DownloadPopupState): FullDocument | null {
            return state.document;
        },
    },
    mutations: {
        SET_VISIBILITY(state: DownloadPopupState, payload: boolean): void {
            state.isVisible = payload;
        },
        SET_DOCUMENT(
            state: DownloadPopupState,
            payload: FullDocument | null
        ): void {
            payload
                ? (state.document = clone(payload))
                : (state.document = null);
        },
    },
    actions: {
        OPEN_POPUP(
            context: ActionContext<DownloadPopupState, unknown>,
            payload: FullDocument | null
        ): void {
            if (payload) {
                context.commit('SET_DOCUMENT', payload);
                context.commit('SET_VISIBILITY', true);
            }
        },
        CLOSE_POPUP(context: ActionContext<DownloadPopupState, unknown>): void {
            context.commit('SET_VISIBILITY', false);
            context.commit('SET_DOCUMENT', null);
        },
    },
};
