






import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'AuthLayout',
})
export default class AuthLayout extends Vue {}
