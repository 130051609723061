import {
    defaultQueryObject,
    QueryObject,
    WhereIdObject,
} from '@/types/requests/filters';
import { clone } from '@/helpers/objects';
import { Filter } from '@/types/front-data-types/filter';
import { ActionContext, GetterTree } from 'vuex';
import graphql from '@/plugins/graphql';
import { DocumentsByFiltersRequest } from '@/types/requests/documents';
import GetDocsByFilter from '@/graphql/filters/GetDocsByFilters.gql';
import GetDocsByCreatedAtSorting from '@/graphql/filters/GetDocsByCreatedAtSorting.gql';
import GetDocsByTitleSorting from '@/graphql/filters/GetDocsByTitleSorting.gql';
import {
    defaultFullDocumentList,
    defaultFullDocumentListFrontData,
    FullDocumentList,
    FullDocumentListFrontData,
} from '@/types/front-data-types/full-docs';

export type SSFState = {
    filterResult: { count: number; list: FullDocumentList };
    searchResult: {
        inTitle: { count: number; list: FullDocumentList };
        inText: { count: number; list: FullDocumentList };
        inBook: { count: number; list: FullDocumentList };
        inTags: { count: number; list: FullDocumentList };
    };
    searchTag: string;
};

export default {
    namespaced: true,
    state: {
        filterResult: clone(defaultFullDocumentListFrontData),
        searchResult: {
            inTitle: clone(defaultFullDocumentListFrontData),
            inText: clone(defaultFullDocumentListFrontData),
            inBook: clone(defaultFullDocumentListFrontData),
            inTags: clone(defaultFullDocumentListFrontData),
        },
        searchTag: '',
    },
    getters: <GetterTree<SSFState, any>>{
        FILTER_RESULT: function (state: SSFState): {
            count: number;
            list: FullDocumentList;
        } {
            return state.filterResult;
        },
        SEARCH_RESULT: function (state: SSFState): {
            inTitle: { count: number; list: FullDocumentList };
            inText: { count: number; list: FullDocumentList };
            inBook: { count: number; list: FullDocumentList };
            inTags: { count: number; list: FullDocumentList };
        } {
            console.log('SEARCH_RESULT', state.searchResult);
            return state.searchResult;
        },
        FILTER_QUERY_OBJECT: function (
            state: SSFState,
            getters,
            rootState,
            rootGetters
        ): QueryObject {
            const query: QueryObject = clone(defaultQueryObject);
            const selectedFilters: Filter =
                rootGetters['Filters/SELECTED_FILTERS'];
            const currentProject: WhereIdObject | undefined =
                rootGetters['Filters/CURRENT_PROJECT_ID'];

            for (const [key, value] of Object.entries(selectedFilters)) {
                if (
                    key === 'practice' &&
                    selectedFilters[key].options.length > 0
                ) {
                    value.options.forEach((option) => {
                        if (
                            query.where.proceedings_some &&
                            query.where.proceedings_some.id_in
                        ) {
                            query.where.proceedings_some.id_in.push(option.id);
                        } else {
                            query.where.proceedings_some = {
                                id_in: [option.id],
                            };
                        }
                    });
                } else if (
                    key === 'industry' &&
                    selectedFilters[key].options.length > 0
                ) {
                    value.options.forEach((option) => {
                        if (
                            query.where.industries_some &&
                            query.where.industries_some.id_in
                        ) {
                            query.where.industries_some.id_in.push(option.id);
                        } else {
                            query.where.industries_some = {
                                id_in: [option.id],
                            };
                        }
                    });
                } else if (
                    key === 'materials' &&
                    selectedFilters.materials &&
                    selectedFilters.materials.options.length > 0
                ) {
                    value.options.forEach((option) => {
                        if (
                            query.where.material &&
                            query.where.material.id_in
                        ) {
                            query.where.material.id_in.push(option.id);
                        } else {
                            query.where.material = { id_in: [option.id] };
                        }
                    });
                } else if (
                    key === 'documentTypes' &&
                    selectedFilters.documentTypes &&
                    selectedFilters.documentTypes.options.length > 0
                ) {
                    value.options.forEach((option) => {
                        if (query.where.type && query.where.type.id_in) {
                            query.where.type.id_in.push(option.id);
                        } else {
                            query.where.type = { id_in: [option.id] };
                        }
                    });
                } else if (
                    key === 'lang' &&
                    selectedFilters[key].options.length > 0
                ) {
                    const selectedLangs = value.options.map(
                        (option) => option.id
                    );

                    // "AND": [
                    //     {
                    //         "OR": [
                    //             {
                    //                 "language": {
                    //                     "id_in": ["1"]
                    //                 },
                    //                 "additional_language": {
                    //                     "id_in": ["1"]
                    //                 }
                    //             }
                    //         ]
                    //     },
                    //     {
                    //         "OR": [
                    //             {
                    //                 "subAuthors_some": {
                    //                     "id_in": ["Anna Konovalova"]
                    //                 },
                    //                 "owner": {
                    //                     "id_in": ["Anna Konovalova"]
                    //                 }
                    //             }
                    //         ]
                    //     }
                    // ]
                    if (selectedLangs.length !== 0) {
                        if (query.where.AND) {
                            query.where.AND.push({
                                OR: [
                                    {
                                        language: {
                                            id_in: selectedLangs,
                                        },
                                        additional_language: {
                                            id_in: selectedLangs,
                                        },
                                    },
                                ],
                            });
                        } else {
                            query.where.AND = [
                                {
                                    OR: [
                                        {
                                            language: {
                                                id_in: selectedLangs,
                                            },
                                            additional_language: {
                                                id_in: selectedLangs,
                                            },
                                        },
                                    ],
                                },
                            ];
                        }
                    }
                } else if (
                    key === 'year' &&
                    selectedFilters[key].options.length > 0
                ) {
                    value.options.forEach((option) => {
                        if (
                            query.where.year_in &&
                            query.where.year_in.length > 0
                        ) {
                            query.where.year_in.push(option.name);
                        } else {
                            query.where.year_in = [option.name];
                        }
                    });
                } else if (key === 'author' && selectedFilters[key]) {
                    const authorsOrWhereObject: {
                        subAuthors_some: {
                            id_in: string[];
                        };
                        owner: {
                            id_in: string[];
                        };
                    } = {
                        subAuthors_some: {
                            id_in: [],
                        },
                        owner: {
                            id_in: [],
                        },
                    };
                    value.options.forEach((option) => {
                        if (
                            authorsOrWhereObject.subAuthors_some &&
                            authorsOrWhereObject.subAuthors_some.id_in
                        ) {
                            authorsOrWhereObject.subAuthors_some.id_in.push(
                                option.id
                            );
                        } else {
                            authorsOrWhereObject.subAuthors_some = {
                                id_in: [option.id],
                            };
                        }

                        if (
                            authorsOrWhereObject.owner &&
                            authorsOrWhereObject.owner.id_in
                        ) {
                            authorsOrWhereObject.owner.id_in.push(option.id);
                        } else {
                            authorsOrWhereObject.owner = { id_in: [option.id] };
                        }
                    });

                    if (
                        authorsOrWhereObject.subAuthors_some.id_in.length !==
                            0 ||
                        authorsOrWhereObject.owner.id_in.length !== 0
                    ) {
                        if (query.where.AND) {
                            query.where.AND.push({
                                OR: [authorsOrWhereObject],
                            });
                        } else {
                            query.where.AND = [
                                {
                                    OR: [authorsOrWhereObject],
                                },
                            ];
                        }
                    }
                }
            }

            if (currentProject) {
                query.where.project = currentProject;
            }

            return query;
        },
        SEARCH_QUERY_OBJECT_DATA: function (
            state: SSFState,
            getters,
            rootState,
            rootGetters
        ): {
            inTitle: QueryObject;
            inText: QueryObject;
            inBook: QueryObject;
            inTags: QueryObject;
        } {
            const filterQuery: QueryObject = clone(getters.FILTER_QUERY_OBJECT);
            const searchTitleQuery: QueryObject = clone({
                ...defaultQueryObject,
                ...{
                    where: {
                        ...filterQuery.where,
                        OR: [
                            {
                                title_contains_i:
                                    rootGetters['Search/SEARCH_VALUE'],
                                description_contains_i:
                                    rootGetters['Search/SEARCH_VALUE'],
                            },
                        ],
                    },
                },
            });
            const searchTextQuery: QueryObject = clone({
                ...defaultQueryObject,
                ...{
                    where: {
                        ...filterQuery.where,
                        fullText_contains_i: rootGetters['Search/SEARCH_VALUE'],
                    },
                },
            });
            const searchBookQuery: QueryObject = clone({
                ...defaultQueryObject,
                ...{
                    where: {
                        ...filterQuery.where,
                        title_contains_i: rootGetters['Search/SEARCH_VALUE'],
                        fullText_contains_i: rootGetters['Search/SEARCH_VALUE'],
                        isBook: true,
                    },
                },
            });
            console.log(
                'getters.SEARCH_TAG',
                rootGetters['Search/IS_SEARCH_TAG'],
                rootGetters['Search/SEARCH_VALUE']
            );
            const searchInTagsQuery: QueryObject = clone({
                ...defaultQueryObject,
                ...{
                    where: {
                        ...filterQuery.where,
                        tags_some: {
                            name_i: rootGetters['Search/IS_SEARCH_TAG']
                                ? rootGetters['Search/SEARCH_VALUE']
                                      .slice(1)
                                      .toLowerCase()
                                : rootGetters[
                                      'Search/SEARCH_VALUE'
                                  ].toLowerCase(),
                        },
                    },
                },
            });

            return {
                inTitle: searchTitleQuery,
                inText: searchTextQuery,
                inBook: searchBookQuery,
                inTags: searchInTagsQuery,
            };
        },
        SORT_QUERY_OBJECT_DATA: function (
            state: SSFState,
            getters,
            rootState,
            rootGetters
        ):
            | {
                  inTitle: QueryObject;
                  inText: QueryObject;
                  inBook: QueryObject;
                  inTags: QueryObject;
              }
            | QueryObject {
            let filterQuery: QueryObject;
            const searchQueryData:
                | {
                      inTitle: QueryObject;
                      inText: QueryObject;
                      inBook: QueryObject;
                      inTags: QueryObject;
                  }
                | undefined = rootGetters['Search/SEARCH_VALUE']
                ? clone(getters.SEARCH_QUERY_OBJECT_DATA)
                : undefined;

            if (!searchQueryData) {
                filterQuery = clone(getters.FILTER_QUERY_OBJECT);

                return {
                    where: { ...filterQuery.where },
                };
            } else {
                return {
                    inTitle: {
                        where: { ...searchQueryData.inTitle.where },
                    },
                    inText: {
                        where: { ...searchQueryData.inText.where },
                    },
                    inBook: {
                        where: { ...searchQueryData.inBook.where },
                    },
                    inTags: {
                        where: { ...searchQueryData.inTags.where },
                    },
                };
            }
        },
    },
    mutations: {
        SET_FILTER_RESULT(
            state: SSFState,
            payload: { count: number; list: FullDocumentList }
        ): void {
            state.filterResult = clone(payload);
        },
        SET_SEARCH_RESULT_IN_TITLE(
            state: SSFState,
            payload: { count: number; list: FullDocumentList }
        ): void {
            state.searchResult.inTitle = clone(payload);
        },
        SET_SEARCH_RESULT_IN_TEXT(
            state: SSFState,
            payload: { count: number; list: FullDocumentList }
        ): void {
            state.searchResult.inText = clone(payload);
        },
        SET_SEARCH_RESULT_IN_BOOK(
            state: SSFState,
            payload: { count: number; list: FullDocumentList }
        ): void {
            state.searchResult.inBook = clone(payload);
        },
        SET_SEARCH_RESULT_IN_TAGS(
            state: SSFState,
            payload: { count: number; list: FullDocumentList }
        ): void {
            state.searchResult.inTags = clone(payload);
        },
    },
    actions: {
        CLEAR_FILTER_RESULT(context: ActionContext<SSFState, unknown>): void {
            context.commit('SET_FILTER_RESULT', {
                count: 0,
                list: clone(defaultFullDocumentList),
            });
        },
        CLEAR_SEARCH_RESULT(context: ActionContext<SSFState, unknown>): void {
            context.commit(
                'SET_SEARCH_RESULT_IN_TITLE',
                clone(defaultFullDocumentListFrontData)
            );
            context.commit(
                'SET_SEARCH_RESULT_IN_TEXT',
                clone(defaultFullDocumentListFrontData)
            );
            context.commit(
                'SET_SEARCH_RESULT_IN_BOOK',
                clone(defaultFullDocumentListFrontData)
            );
        },

        async LOAD_SSF_RESULT(
            context: ActionContext<SSFState, unknown>,
            payload: {
                isBook: boolean;
                isLoadMore: boolean;
                searchedListPropName?: string;
            }
        ): Promise<void> {
            const sortValue = context.rootGetters['Sort/SORT_VALUE'];
            const searchValue = context.rootGetters['Search/SEARCH_VALUE'];

            if (
                (sortValue && sortValue === 'За датою') ||
                sortValue === 'За алфавітом'
            ) {
                await context.dispatch('LOAD_SORT_RESULT', {
                    isBook: payload.isBook,
                    isLoadMore: payload.isLoadMore,
                    searchedListPropName: payload.searchedListPropName,
                });
            } else if (searchValue) {
                console.log({ searchValue });
                await context.dispatch('LOAD_SEARCH_RESULT', {
                    isBook: payload.isBook,
                    isLoadMore: payload.isLoadMore,
                    searchedListPropName: payload.searchedListPropName,
                });
            } else {
                await context.dispatch('LOAD_FILTER_RESULT', {
                    isBook: payload.isBook,
                    isLoadMore: payload.isLoadMore,
                });
            }
        },

        async LOAD_FILTER_RESULT(
            context: ActionContext<SSFState, unknown>,
            payload: {
                isBook: boolean;
                isLoadMore: boolean;
            }
        ): Promise<void> {
            await context.dispatch('CLEAR_SEARCH_RESULT');
            const skip = payload.isLoadMore
                ? context.getters.FILTER_RESULT.list.length
                : 0;
            let newListData: FullDocumentListFrontData = clone(
                defaultFullDocumentListFrontData
            );

            const filterQueryObject: QueryObject = payload.isBook
                ? {
                      where: {
                          ...context.getters.FILTER_QUERY_OBJECT.where,
                          isBook: true,
                      },
                      skip,
                  }
                : { ...context.getters.FILTER_QUERY_OBJECT, skip };
            const filterResult = await graphql<DocumentsByFiltersRequest>(
                GetDocsByFilter,
                filterQueryObject
            );

            newListData = filterResult
                ? {
                      count: Number(filterResult?._allDocumentsMeta?.count),
                      list: clone(filterResult.allDocuments),
                  }
                : clone(defaultFullDocumentListFrontData);

            if (payload.isLoadMore) {
                context.commit('SET_FILTER_RESULT', {
                    count: newListData.count,
                    list: [
                        ...context.getters.FILTER_RESULT.list,
                        ...newListData.list,
                    ],
                });
            } else {
                context.commit('SET_FILTER_RESULT', newListData);
            }
        },

        async LOAD_SEARCH_RESULT(
            context: ActionContext<SSFState, unknown>,
            payload: {
                isBook: boolean;
                isLoadMore: boolean;
                searchedListPropName?: string;
            }
        ): Promise<void> {
            await context.dispatch('CLEAR_FILTER_RESULT');

            const searchQueryData = context.getters.SEARCH_QUERY_OBJECT_DATA;
            const searchQueryDataArray = Object.entries(searchQueryData);
            for await (const query of searchQueryDataArray) {
                const keyName = query[0];

                if (
                    context.rootGetters['Search/IS_SEARCH_TAG'] &&
                    keyName !== 'inTags'
                ) {
                    continue;
                }

                console.dir(keyName);

                const skip = payload.isLoadMore
                    ? context.getters.SEARCH_RESULT[keyName].list.length
                    : 0;
                console.log(keyName, context.getters.SEARCH_RESULT[keyName]);
                const currentQueryObject: QueryObject = payload.isBook
                    ? {
                          where: {
                              ...searchQueryData[keyName].where,
                              isBook: true,
                          },
                          skip,
                      }
                    : { ...searchQueryData[keyName], skip };
                console.log({ currentQueryObject });
                const searchResult = await graphql<DocumentsByFiltersRequest>(
                    GetDocsByFilter,
                    currentQueryObject
                );
                console.log({ keyName, searchResult });
                const newListData = searchResult
                    ? {
                          count: Number(searchResult?._allDocumentsMeta?.count),
                          list: clone(searchResult.allDocuments),
                      }
                    : clone(defaultFullDocumentListFrontData);

                switch (keyName) {
                    case 'inTitle':
                        if (
                            payload.isLoadMore &&
                            payload.searchedListPropName &&
                            payload.searchedListPropName === keyName
                        ) {
                            context.commit('SET_SEARCH_RESULT_IN_TITLE', {
                                list: [
                                    ...context.getters.SEARCH_RESULT[keyName]
                                        .list,
                                    ...newListData.list,
                                ],
                                count: newListData.count,
                            });
                        }
                        if (!payload.isLoadMore) {
                            context.commit(
                                'SET_SEARCH_RESULT_IN_TITLE',
                                newListData
                            );
                        }

                        break;
                    case 'inText':
                        if (
                            payload.isLoadMore &&
                            payload.searchedListPropName &&
                            payload.searchedListPropName === keyName
                        ) {
                            newListData.list = newListData.list.map((item) => {
                                const fullTextSearchIndex = item.fullText
                                    .toLowerCase()
                                    .indexOf(
                                        context.rootGetters[
                                            'Search/SEARCH_VALUE'
                                        ].toLowerCase()
                                    );
                                return {
                                    ...item,
                                    fullText: item.fullText.substring(
                                        fullTextSearchIndex,
                                        fullTextSearchIndex + 200
                                    ),
                                };
                            });
                            context.commit('SET_SEARCH_RESULT_IN_TEXT', {
                                list: [
                                    ...context.getters.SEARCH_RESULT[keyName]
                                        .list,
                                    ...newListData.list,
                                ],
                                count: newListData.count,
                            });
                        }
                        if (!payload.isLoadMore) {
                            newListData.list = newListData.list.map((item) => {
                                const fullTextSearchIndex = item.fullText
                                    .toLowerCase()
                                    .indexOf(
                                        context.rootGetters[
                                            'Search/SEARCH_VALUE'
                                        ].toLowerCase()
                                    );
                                return {
                                    ...item,
                                    fullText: item.fullText.substring(
                                        fullTextSearchIndex,
                                        fullTextSearchIndex + 200
                                    ),
                                };
                            });
                            context.commit(
                                'SET_SEARCH_RESULT_IN_TEXT',
                                newListData
                            );
                        }

                        break;
                    case 'inBook':
                        if (
                            payload.isLoadMore &&
                            payload.searchedListPropName &&
                            payload.searchedListPropName === keyName
                        ) {
                            newListData.list = newListData.list.map((item) => {
                                const fullTextSearchIndex = item.fullText
                                    .toLowerCase()
                                    .indexOf(
                                        context.rootGetters[
                                            'Search/SEARCH_VALUE'
                                        ].toLowerCase()
                                    );
                                return {
                                    ...item,
                                    fullText: item.fullText.substring(
                                        fullTextSearchIndex,
                                        fullTextSearchIndex + 200
                                    ),
                                };
                            });
                            context.commit('SET_SEARCH_RESULT_IN_BOOK', {
                                list: [
                                    ...context.getters.SEARCH_RESULT[keyName]
                                        .list,
                                    ...newListData.list,
                                ],
                                count: newListData.count,
                            });
                        }
                        if (!payload.isLoadMore) {
                            newListData.list = newListData.list.map((item) => {
                                const fullTextSearchIndex = item.fullText
                                    .toLowerCase()
                                    .indexOf(
                                        context.rootGetters[
                                            'Search/SEARCH_VALUE'
                                        ].toLowerCase()
                                    );
                                return {
                                    ...item,
                                    fullText: item.fullText.substring(
                                        fullTextSearchIndex,
                                        fullTextSearchIndex + 200
                                    ),
                                };
                            });
                            context.commit(
                                'SET_SEARCH_RESULT_IN_BOOK',
                                newListData
                            );
                        }

                        break;
                    case 'inTags':
                        console.log('inTags|inTags|inTags');
                        console.log(
                            1,
                            context.getters.SEARCH_RESULT[keyName].list,
                            newListData.list
                        );
                        if (
                            payload.isLoadMore &&
                            payload.searchedListPropName &&
                            payload.searchedListPropName === keyName
                        ) {
                            console.log('case in tags');
                            context.commit('SET_SEARCH_RESULT_IN_TAGS', {
                                list: [
                                    ...context.getters.SEARCH_RESULT[keyName]
                                        .list,
                                    ...newListData.list,
                                ],
                                count: newListData.count,
                            });
                        }
                        if (!payload.isLoadMore) {
                            context.commit(
                                'SET_SEARCH_RESULT_IN_TAGS',
                                newListData
                            );
                        }
                        break;
                }
            }
            console.log('LOAD_SEARCH_RESULT', 2);
        },

        async LOAD_SORT_RESULT(
            context: ActionContext<SSFState, unknown>,
            payload: {
                isBook: boolean;
                isLoadMore: boolean;
                searchedListPropName?: string;
            }
        ): Promise<void> {
            const sortQueryData = context.getters.SORT_QUERY_OBJECT_DATA;
            console.log('LOAD_SORT_RESULT');
            if (
                !Object.prototype.hasOwnProperty.call(sortQueryData, 'inTitle')
            ) {
                await context.dispatch('CLEAR_SEARCH_RESULT');

                const skip = payload.isLoadMore
                    ? context.getters.FILTER_RESULT.list.length
                    : 0;
                const currentQueryObject: QueryObject = payload.isBook
                    ? {
                          where: {
                              ...sortQueryData.where,
                              isBook: true,
                          },
                          skip,
                      }
                    : { ...sortQueryData, skip };

                const gqlQuery =
                    context.rootGetters['Sort/SORT_VALUE'] === 'За алфавітом'
                        ? GetDocsByTitleSorting
                        : GetDocsByCreatedAtSorting;
                const sortResult = await graphql<DocumentsByFiltersRequest>(
                    gqlQuery,
                    currentQueryObject
                );

                const newListData = sortResult
                    ? {
                          count: Number(sortResult?._allDocumentsMeta?.count),
                          list: clone(sortResult.allDocuments),
                      }
                    : clone(defaultFullDocumentListFrontData);

                if (payload.isLoadMore) {
                    context.commit('SET_FILTER_RESULT', {
                        list: [
                            ...context.getters.FILTER_RESULT.list,
                            ...newListData.list,
                        ],
                        count: newListData.count,
                    });
                } else {
                    context.commit('SET_FILTER_RESULT', newListData);
                }
            } else {
                await context.dispatch('CLEAR_FILTER_RESULT');
                const sortQueryDataArray = Object.entries(
                    context.getters.SORT_QUERY_OBJECT_DATA
                );

                for await (const query of sortQueryDataArray) {
                    const keyName = query[0];

                    if (
                        context.rootGetters['Search/IS_SEARCH_TAG'] &&
                        keyName !== 'inTags'
                    ) {
                        continue;
                    }

                    const skip = payload.isLoadMore
                        ? context.getters.SEARCH_RESULT[keyName].list.length
                        : 0;
                    const currentQueryObject: QueryObject = payload.isBook
                        ? {
                              where: {
                                  ...sortQueryData[keyName].where,
                                  isBook: true,
                              },
                              skip,
                          }
                        : { ...sortQueryData[keyName], skip };

                    const gqlQuery =
                        context.rootGetters['Sort/SORT_VALUE'] ===
                        'За алфавітом'
                            ? GetDocsByTitleSorting
                            : GetDocsByCreatedAtSorting;
                    const sortResult = await graphql<DocumentsByFiltersRequest>(
                        gqlQuery,
                        currentQueryObject
                    );

                    const newListData = sortResult
                        ? {
                              count: Number(
                                  sortResult?._allDocumentsMeta?.count
                              ),
                              list: clone(sortResult.allDocuments),
                          }
                        : clone(defaultFullDocumentListFrontData);

                    switch (keyName) {
                        case 'inTitle':
                            if (
                                payload.isLoadMore &&
                                payload.searchedListPropName &&
                                payload.searchedListPropName === keyName
                            ) {
                                console.log('sorting', skip);

                                context.commit('SET_SEARCH_RESULT_IN_TITLE', {
                                    list: [
                                        ...context.getters.SEARCH_RESULT[
                                            keyName
                                        ].list,
                                        ...newListData.list,
                                    ],
                                    count: newListData.count,
                                });
                            }
                            if (!payload.isLoadMore) {
                                context.commit(
                                    'SET_SEARCH_RESULT_IN_TITLE',
                                    newListData
                                );
                            }
                            break;
                        case 'inText':
                            if (
                                payload.isLoadMore &&
                                payload.searchedListPropName &&
                                payload.searchedListPropName === keyName
                            ) {
                                newListData.list = newListData.list.map(
                                    (item) => {
                                        const fullTextSearchIndex =
                                            item.fullText
                                                .toLowerCase()
                                                .indexOf(
                                                    context.rootGetters[
                                                        'Search/SEARCH_VALUE'
                                                    ].toLowerCase()
                                                );
                                        return {
                                            ...item,
                                            fullText: item.fullText.substring(
                                                fullTextSearchIndex,
                                                fullTextSearchIndex + 200
                                            ),
                                        };
                                    }
                                );
                                context.commit('SET_SEARCH_RESULT_IN_TEXT', {
                                    list: [
                                        ...context.getters.SEARCH_RESULT[
                                            keyName
                                        ].list,
                                        ...newListData.list,
                                    ],
                                    count: newListData.count,
                                });
                            }
                            if (!payload.isLoadMore) {
                                newListData.list = newListData.list.map(
                                    (item) => {
                                        const fullTextSearchIndex =
                                            item.fullText
                                                .toLowerCase()
                                                .indexOf(
                                                    context.rootGetters[
                                                        'Search/SEARCH_VALUE'
                                                    ].toLowerCase()
                                                );
                                        return {
                                            ...item,
                                            fullText: item.fullText.substring(
                                                fullTextSearchIndex,
                                                fullTextSearchIndex + 200
                                            ),
                                        };
                                    }
                                );
                                context.commit(
                                    'SET_SEARCH_RESULT_IN_TEXT',
                                    newListData
                                );
                            }
                            break;
                        case 'inBook':
                            if (
                                payload.isLoadMore &&
                                payload.searchedListPropName &&
                                payload.searchedListPropName === keyName
                            ) {
                                newListData.list = newListData.list.map(
                                    (item) => {
                                        const fullTextSearchIndex =
                                            item.fullText
                                                .toLowerCase()
                                                .indexOf(
                                                    context.rootGetters[
                                                        'Search/SEARCH_VALUE'
                                                    ].toLowerCase()
                                                );
                                        return {
                                            ...item,
                                            fullText: item.fullText.substring(
                                                fullTextSearchIndex,
                                                fullTextSearchIndex + 200
                                            ),
                                        };
                                    }
                                );
                                context.commit('SET_SEARCH_RESULT_IN_BOOK', {
                                    list: [
                                        ...context.getters.SEARCH_RESULT[
                                            keyName
                                        ].list,
                                        ...newListData.list,
                                    ],
                                    count: newListData.count,
                                });
                            }
                            if (!payload.isLoadMore) {
                                newListData.list = newListData.list.map(
                                    (item) => {
                                        const fullTextSearchIndex =
                                            item.fullText
                                                .toLowerCase()
                                                .indexOf(
                                                    context.rootGetters[
                                                        'Search/SEARCH_VALUE'
                                                    ].toLowerCase()
                                                );
                                        return {
                                            ...item,
                                            fullText: item.fullText.substring(
                                                fullTextSearchIndex,
                                                fullTextSearchIndex + 200
                                            ),
                                        };
                                    }
                                );
                                context.commit(
                                    'SET_SEARCH_RESULT_IN_BOOK',
                                    newListData
                                );
                            }
                            break;
                    }
                }
            }
        },
    },
};
