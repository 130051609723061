import { ActionContext } from 'vuex';
import {
    AllAuthorsRequest,
    AllDocumentsTypesRequest,
    AllFilters,
    AllIndustriesRequest,
    AllLanguagesRequest,
    AllMaterialsRequest,
    AllOwnersAndYearsRequest,
    AllProceedingsRequest,
    AllProjectsRequest,
    Filters,
    WhereIdObject,
} from '@/types/requests/filters';
import {
    defaultFilter,
    Filter,
    FilterCategoryWithOptions,
    FilterCategoryWithOptionsFull,
    FilterOption,
} from '@/types/front-data-types/filter';

import { clone } from '@/helpers/objects';

import graphql from '@/plugins/graphql';
import GetAuthors from '@/graphql/filters/GetAuthors.gql';
import GetDocumentTypes from '@/graphql/filters/GetDocumentTypes.gql';
import GetIndustries from '@/graphql/filters/GetIndustries.gql';
import GetLanguages from '@/graphql/filters/GetLanguages.gql';
import GetMaterials from '@/graphql/filters/GetMaterials.gql';
import GetProceedings from '@/graphql/filters/GetProceedings.gql';
import GetProjects from '@/graphql/filters/GetProjects.gql';
import GetOwnersAndYars from '@/graphql/filters/GetOwnersAndYears.gql';
import { sliceAuthorName } from '@/helpers/sliceAuthorName';

export type FiltersState = {
    filters: Filter;
    selectedFilters: Filter;
    currentProjectId: WhereIdObject | undefined;
};

export default {
    namespaced: true,
    state: {
        filters: clone(defaultFilter),
        selectedFilters: clone(defaultFilter),
        currentProjectId: {
            id: undefined,
        },
    },
    // TODO: Прописать типы для параметров геттера
    getters: {
        FILTERS: function (state: FiltersState): Filter {
            return state.filters;
        },
        SELECTED_FILTERS: function (state: FiltersState): Filter {
            return state.selectedFilters;
        },
        CURRENT_PROJECT_ID: function (
            state: FiltersState
        ): WhereIdObject | undefined {
            return state.currentProjectId;
        },
        SELECTED_FILTERS_TAGS: function (state: FiltersState): FilterOption[] {
            const entriesSelectedFilter = Object.values(state.selectedFilters);
            const selectedFilterTags: FilterOption[] = [];
            entriesSelectedFilter.forEach(
                (category: FilterCategoryWithOptions): void | boolean => {
                    const categoryId = category.id;

                    if (!category.options) return false;
                    category.options.forEach(
                        (option: FilterOption | string): void => {
                            if (typeof option !== 'string') {
                                selectedFilterTags.push({
                                    ...option,
                                    category_id: categoryId,
                                });
                            }
                        }
                    );
                }
            );

            return selectedFilterTags;
        },
        FOUNDED_SELECTED_OPTION: function (
            state: FiltersState
        ): (payload: { categoryId: string; optionId: string }) => boolean {
            return (payload: {
                categoryId: string;
                optionId: string;
            }): boolean => {
                const entriesSelectedFilter = Object.values(
                    state.selectedFilters
                );
                const currentCategory:
                    | FilterCategoryWithOptionsFull
                    | undefined = entriesSelectedFilter.find(
                    (category: FilterCategoryWithOptionsFull): boolean =>
                        payload.categoryId === category.id
                );
                if (currentCategory) {
                    if (!currentCategory.options) return false;
                    return !!currentCategory.options.find(
                        (option: FilterOption | string): boolean => {
                            if (option && typeof option !== 'string') {
                                return payload.optionId === option.id;
                            }
                            return false;
                        }
                    );
                } else {
                    return false;
                }
            };
        },
    },
    mutations: {
        SET_CURRENT_PROJECT_ID(
            state: FiltersState,
            payload: WhereIdObject | undefined
        ): void {
            state.currentProjectId = payload;
        },
        SET_FILTERS(state: FiltersState, payload: Filter): void {
            state.filters = clone(payload);
        },
        SET_SELECTED_FILTERS(state: FiltersState, payload: Filter): void {
            state.selectedFilters = clone(payload);
        },
    },
    actions: {
        UPDATE_FILTERS_OPTIONS(
            context: ActionContext<FiltersState, unknown>,
            payload: AllFilters
        ): void {
            const oldFilters: Filter = clone(context.getters.FILTERS);
            if (payload) {
                const filters = new Filters(payload);
                oldFilters.practice.options = filters.proceedings;
                oldFilters.industry.options = filters.industries;
                oldFilters.year.options = filters.years.sort((a, b) => {
                    return +a.name > +b.name ? 0 : 1;
                });
                if (oldFilters.materials)
                    oldFilters.materials.options = filters.materials;
                if (oldFilters.documentTypes)
                    oldFilters.documentTypes.options = filters.documentTypes;
                oldFilters.lang.options = filters.languages;
                if (oldFilters.author) {
                    oldFilters.author.options = filters.authors
                        .sort((a, b) => {
                            return a.name > b.name ? 1 : 0;
                        })
                        .map((item) => {
                            item.name = sliceAuthorName(item.name);
                            return item;
                        });
                }
            }
            context.commit('SET_FILTERS', oldFilters);
        },
        LOAD_CUSTOM_FILTERS(
            context: ActionContext<FiltersState, unknown>,
            payload: AllFilters
        ): void {
            context.commit('SET_FILTERS', payload);
            context.commit('SET_SELECTED_FILTERS', payload);
        },
        CLEAR_SELECTED_FILTERS(
            context: ActionContext<FiltersState, unknown>
        ): void {
            context.commit('SET_SELECTED_FILTERS', defaultFilter);
        },
        LOAD_CURRENT_PROJECT_ID(
            context: ActionContext<FiltersState, unknown>,
            payload: WhereIdObject | undefined
        ): void {
            context.commit('SET_CURRENT_PROJECT_ID', payload);
        },
        TOGGLE_OPTION(
            context: ActionContext<FiltersState, unknown>,
            payload: { categoryId: string; option: FilterOption }
        ): void {
            const selectedFilters = context.getters.SELECTED_FILTERS;
            const entriesSelectedFilter: [string, FilterCategoryWithOptions][] =
                Object.entries(selectedFilters);

            for (const [key, category] of entriesSelectedFilter) {
                if (payload.categoryId === category.id) {
                    if (
                        !selectedFilters[key].options.find(
                            (
                                selectedOption: FilterOption,
                                i: number
                            ): boolean => {
                                if (selectedOption.id === payload.option.id) {
                                    selectedFilters[key].options.splice(i, 1);
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        )
                    ) {
                        selectedFilters[key].options.push(payload.option);
                    }
                }
            }

            context.commit('SET_SELECTED_FILTERS', selectedFilters);
        },
        async LOAD_ALL_FILTERS(
            context: ActionContext<FiltersState, unknown>
        ): Promise<void> {
            try {
                const reqAuthors = graphql<AllAuthorsRequest>(GetAuthors);
                const reqDocTypes =
                    graphql<AllDocumentsTypesRequest>(GetDocumentTypes);
                const reqIndustries =
                    graphql<AllIndustriesRequest>(GetIndustries);
                const reqLanguages = graphql<AllLanguagesRequest>(GetLanguages);
                const reqMaterials = graphql<AllMaterialsRequest>(GetMaterials);
                const reqProceedings =
                    graphql<AllProceedingsRequest>(GetProceedings);
                const reqProjects = graphql<AllProjectsRequest>(GetProjects);
                const reqOwnersAndYears =
                    graphql<AllOwnersAndYearsRequest>(GetOwnersAndYars);

                const result: AllFilters = await Promise.all([
                    reqAuthors,
                    reqDocTypes,
                    reqIndustries,
                    reqLanguages,
                    reqMaterials,
                    reqProceedings,
                    reqProjects,
                    reqOwnersAndYears,
                ]);

                await context.dispatch('UPDATE_FILTERS_OPTIONS', result);
            } catch (e) {
                console.error('Error, loading suggestions list', e);
            }
        },
    },
};
