









import { Component, Prop, Vue } from 'vue-property-decorator';
import MiniDoc from '@/components/block/mini-doc.vue';
import {
    defaultMiniDocumentList,
    MiniDocumentList,
} from '@/types/front-data-types/mini-docs';

@Component({
    name: 'MiniDocs',
    components: {
        MiniDoc,
    },
})
export default class MiniDocs extends Vue {
    @Prop({ default: 'Doc list title' }) readonly title!: string;
    @Prop({ default: defaultMiniDocumentList })
    readonly listData!: MiniDocumentList;
}
