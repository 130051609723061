import Vue from 'vue';
import Vuex from 'vuex';

import Search, { SearchState } from '@/store/modules/search';
import Filters, { FiltersState } from '@/store/modules/filters';
import Sort, { SortState } from '@/store/modules/sort';
import SSF, { SSFState } from '@/store/modules/sortSearchFilter';
import Users, { UsersState } from '@/store/modules/users';

import DownloadPopup, {
    DownloadPopupState,
} from '@/store/modules/downloadPopup';

Vue.use(Vuex);

type MyStore = {
    Search: SearchState;
    Filters: FiltersState;
    Sort: SortState;
    SSF: SSFState;
    Users: UsersState;
    DownloadPopup: DownloadPopupState;
};

export default new Vuex.Store<MyStore>({
    modules: {
        Search,
        Filters,
        Sort,
        SSF,
        Users,

        DownloadPopup,
    },
});
