import {defaultFile, FileType} from "@/types/front-data-types/base/file";
import {clone} from "@/helpers/objects";
import {FullDocument, ViewedDoc} from "@/types/front-data-types/full-docs";

export type UserType = {
    avatar: FileType
    id?: string
    isAdmin: boolean
    name: string,
    position?: string
    email: string,
    likedDocuments: FullDocument[],
    viewedDocuments: ViewedDoc[],
    chosenDocuments: FullDocument[]
}

export const defaultUser = {
    avatar: clone(defaultFile),
    id: '',
    isAdmin: false,
    name: '',
    email: '',
    likedDocuments: [],
    viewedDocuments: [],
    chosenDocuments: []
}
