






import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'ArMainContainer',
})
export default class ArMainContainer extends Vue {}
