export type FilterOption = {
    id: string;
    category_id?: string;
    name: string;
};

export type SuggestionType = {
    name: string;
    value: string;
    type: string;
};

const defaultFilterOption: FilterOption = {
    id: '1',
    name: 'Option',
};

export type FilterCategoryWithOptions = {
    id: string;
    title: string;
    options: FilterOption[] | string[];
};
export type FilterCategoryWithOptionsFull = FilterCategoryWithOptions & {
    options: FilterOption[];
};
const defaultFilterCategoryWithOptions: FilterCategoryWithOptions = {
    id: '1',
    title: 'Category',
    options: [defaultFilterOption],
};

export type Filter = Record<string, FilterCategoryWithOptionsFull> & {
    practice: FilterCategoryWithOptionsFull;
    industry: FilterCategoryWithOptionsFull;
    documentTypes?: FilterCategoryWithOptionsFull;
    materials?: FilterCategoryWithOptionsFull;
    lang: FilterCategoryWithOptionsFull;
    year: FilterCategoryWithOptionsFull;
    author?: FilterCategoryWithOptionsFull;
};

const defaultFilter: Filter = {
    practice: {
        id: '1',
        title: 'Практика',
        options: [],
    },
    industry: {
        id: '2',
        title: 'Індустрія',
        options: [],
    },
    materials: {
        id: '3',
        title: 'Матеріали',
        options: [],
    },
    documentTypes: {
        id: '4',
        title: 'Тип документу',
        options: [],
    },
    lang: {
        id: '5',
        title: 'Мова',
        options: [],
    },
    year: {
        id: '6',
        title: 'Рік',
        options: [],
    },
    author: {
        id: '7',
        title: 'Автор',
        options: [],
    },
};

export { defaultFilterCategoryWithOptions, defaultFilterOption, defaultFilter };
