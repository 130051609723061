











import { Component, Vue } from 'vue-property-decorator';
import ArHeader from '@/components/section/ar-header.vue';
import ModalDownloadDocument from '@/components/modal/download-document.vue';
import ArSearch from '@/components/form/ar-search.vue';
import MiniDocs from '@/components/block/mini-docs.vue';
import NotFound from '@/components/block/not-found.vue';

@Component({
    name: 'DefaultFoundLayout',
    components: {
        ArHeader,
        ArSearch,
        MiniDocs,
        NotFound,
        ModalDownloadDocument,
    },
})
export default class DefaultFoundLayout extends Vue {}
